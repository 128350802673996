<template>
	<div class="page-content">
		<div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<el-form-item label="名称" prop="name">
					<el-input v-model="searchForm.name" style="width: 220px;" clearable placeholder="请填写"></el-input>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-right: auto;">
					<div class="fill-btn" @click="getList">查询</div>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading" cell-class-name="text-center"
				header-cell-class-name="header-row-class-name">
				<el-table-column prop="id" label="ID" min-width="60px"></el-table-column>
				<el-table-column prop="name" label="名称" min-width="120px"></el-table-column>
				<el-table-column prop="start_num" label="初始成长值" min-width="120px"></el-table-column>
				<el-table-column prop="scale_num" min-width="160px" label="折扣百分比"></el-table-column>
				<el-table-column width="200" fixed="right">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
								@click="handEditShow(row)"></el-button>
							<el-button v-if="row.id!=1" class="el-icon-delete cur-p" type="primary" size="mini" @click="del(row)">
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog :title="`${editId?'编辑':'创建'}`" :visible.sync="showEdit" width="60%">
			<div style="padding: 20px;">
				<el-form ref="forms" :model="editForm" :rules="getRules" label-width="100px">
					<el-form-item label="名称" prop="name">
						<el-input placeholder="请输入名称" v-model="editForm.name"></el-input>
					</el-form-item>
					<el-form-item label="初始成长值" :prop="editId==1?'':'start_num'">
						<el-input :disabled="editId==1" type="number" placeholder="请输入初始成长值" v-model="editForm.start_num"></el-input>
					</el-form-item>
					<el-form-item label="折扣百分比" prop="scale_num">
						<el-input type="number" placeholder="请输入折扣百分比" v-model="editForm.scale_num"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="creating" @click="edit" v-if="editId" style="margin: 10px auto">编辑</el-button>
						<el-button type="primary" :loading="creating" @click="add" v-else style="margin: 10px auto">创建</el-button>
						<el-button  @click="showEdit=false" style="margin: 10px">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		delToast,
		formVerify
	} from './../../utlis/decorators'
	export default {
		name: "memberList",
		data() {
			return {
				loading: false,
				creating: false,
				total: 0,
				showEdit: false,

				searchForm: {
					page: 1,
					limit: 10,
					name: '', //名称
				},
				editForm: {
					name: '', //名称
					start_num: '', //初始成长值
					scale_num: '', //折扣百分比
				},
				editId: '',
				tableData: [],
			}
		},
		created() {
			this.getList();
		},
		computed: {
			getRules() {
				return {
					name: [{
						required: true,
						message: `名称不能为空`,
						trigger: ['blur', 'change']
					}],
					start_num: [{
						required: true,
						message: `初始成长值不能为空`,
						trigger: ['blur', 'change']
					},{
						validator:this.$validate.checkThanNum,
						trigger: ['blur', 'change']
					}],
					scale_num: [{
						required: true,
						message: `折扣百分比不能为空`,
						trigger: ['blur', 'change']
					},{
						validator:this.$validate.checkPercentNum,
						trigger: ['blur', 'change']
					}],
				}
			}
		},
		watch: {
			'searchForm.page'() {
				this.getList();
			},
		},
		methods: {
			handShowAdd() {
				this.editId = '';
				try {
					this.showEdit = true;
					this.$refs['forms'].resetFields()
					for (let i in this.editForm) {
						this.editForm[i] = '';
					}
				} catch {}
			},
			async handEditShow(row) {
				this.editId = row.id;
				for (let i in this.editForm) {
					this.editForm[i] = row[i];
				}
				this.showEdit = true;
			},
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			//获取列表
			async getList() {
				this.loading = true
				let [e, data] = await this.$api.getVipListApi(this.searchForm);
				this.loading = false
				if (e) return;
				if (data.code === 200) {

					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},
			@delToast()
			async del({
				id
			}) {
				let [e, data] = await this.$api.delVipApi({
					id
				});
				if (e) return;
				if (data.code === 200) {
					this.getList();
					this.$message.success('删除成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			

			//添加
			@formVerify('forms')
			async add() {
				this.creating = true;
				let [e, data] = await this.$api.addVipApi(this.editForm);
				if (e){this.creating = false;return;} 
				if (data.code === 200) {
					this.getList();
					this.showEdit = false;
					this.creating = false;	
					this.$message.success('添加成功')
				} else {
					this.creating = false;
					this.$message.warning(`${data.msg}`)
				}
			},
			//编辑
			@formVerify('forms')
			async edit() {
				this.creating = true;
				let [e, data] = await this.$api.editVipApi({
					id:this.editId,
					...this.editForm
				});
				if (e){this.creating = false;return;} 
				if (data.code === 200) {
					this.showEdit = false;
					this.creating = false;
					this.getList();
					this.$message.success('保存成功')
				} else {
					this.creating = false;
					this.$message.warning(`${data.msg}`)
				}
			}
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	/deep/ .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.bm-view {
		width: 800px;
		height: 400px;
	}

	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}
</style>
